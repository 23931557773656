export default {
  services: {
    title: "Services",
    href: "#services",
  },
  experience: {
    title: "Experience",
    href: "#experience",
  },
  contact: {
    title: "Contact",
    href: "#contact",
  },
};
